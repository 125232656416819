const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const EMPTY_CART = 'EMPTY_CART';
const UPDATE_PRODUCT_COUNT = 'UPDATE_PRODUCT_COUNT';

export function addToCartMessage(product, count) {
  return buildMessage(ADD_TO_CART, { product, count });
}

export function updateProductCountMessage(productId, count) {
  return buildMessage(UPDATE_PRODUCT_COUNT, { productId, count });
}

export function removeFromCartMessage(productId) {
  return buildMessage(REMOVE_FROM_CART, { productId });
}

export function emptyCartMessage(){
  return buildMessage(EMPTY_CART,{})
}

function buildMessage(type, payload) {
  return { type, payload };
}

const messageHandlers = {
  [ADD_TO_CART]: addToCart,
  [REMOVE_FROM_CART]: removeFromCart,
  [EMPTY_CART]: emptyCart,
  [UPDATE_PRODUCT_COUNT]: updateProductCount
};

function handleMessage(state, { type, payload }) {
  const handler = messageHandlers[type];

  if (!handler) return state;

  return handler(state, payload);
}

function addToCart(state, { product, count }) {
  //console.log("enter ok")
  let existingProducts = state.products;
  if(existingProducts[product.id]!=null )
  {
    count += existingProducts[product.id].count;
    //console.log("in here" + count + ' ' + existingProducts[product.id].count);
  } 
  // let isHidePrice 
  // if(state.product.hidePrice) {
  //     isHidePrice = -1
  // }

  // console.log('hide', state.product.hidePrice)
  const products = {
    ...state.products,
    [product.id]: { product, count }
  };
  //console.log("adding to cart!!! " + state.products);
  return {
    ...state,
    products
  };
}

function removeFromCart(state, { productId }) {
  const {
    [productId]: removedProduct, // eslint-disable-line no-unused-vars
    ...products
  } = state.products;

  return {
    ...state,
    products
  };
}

function updateProductCount(state, { productId , count}) {
  // console.log("changeCount:" + productId);
  // console.log("changeCount:" + count);
  // console.log("changeCount:" + state.products[productId].count);
  let currentProduct = state.products[productId];
  if(currentProduct.count + count > 0)
  {
    state.products[productId].count += count;
  }
  else
  {
    //console.log("remove product - negative value");
    const {
      [productId]: removedProduct, // eslint-disable-line no-unused-vars
      ...products
    } = state.products;
    
    return {
      ...state,
      products
    };
    
  }
  return {...state};
  
}

function emptyCart(state){
  state.products = {};
  //console.log("emptystore")
  return {
    ...state
  };
}

const initialState = {
  products: {}
};

export function cartReducer(state = initialState, action) {
  if (!action) return state;

  return handleMessage(state, action);
}