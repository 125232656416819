// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-collection-js": () => import("./../../../src/pages/custom-collection.js" /* webpackChunkName: "component---src-pages-custom-collection-js" */),
  "component---src-pages-get-quote-js": () => import("./../../../src/pages/get-quote.js" /* webpackChunkName: "component---src-pages-get-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-material-js": () => import("./../../../src/pages/material.js" /* webpackChunkName: "component---src-pages-material-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news-events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-products-list-js": () => import("./../../../src/pages/products-list.js" /* webpackChunkName: "component---src-pages-products-list-js" */),
  "component---src-templates-branches-js": () => import("./../../../src/templates/branches.js" /* webpackChunkName: "component---src-templates-branches-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-news-article-page-js": () => import("./../../../src/templates/news-article-page.js" /* webpackChunkName: "component---src-templates-news-article-page-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-secondary-page-js": () => import("./../../../src/templates/secondary-page.js" /* webpackChunkName: "component---src-templates-secondary-page-js" */)
}

